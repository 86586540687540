<template>
  <div>
    <van-search v-model="query" show-action @search="search">
      <template #left>
        <van-icon
          name="arrow-left"
          class="search-left"
          @click="$router.back()"
        />
      </template>
      <template #action>
        <div @click="search(query)">搜索</div>
      </template>
    </van-search>
    <list
      v-show="visible"
      class="search-list"
      toast
      :params="params"
      :group-id="groupId"
      :customer-id="customerId"
    />
  </div>
</template>

<script>
import { Search, Icon, Toast } from 'vant'
import List from './_list.vue'
import {
  getContext,
  getCurExternalChat,
  getCurExternalContact
} from '../../../utils/nwechat'

export default {
  name: 'SpeechcraftSearch',
  components: {
    List,
    [Search.name]: Search,
    [Icon.name]: Icon
  },
  data() {
    return {
      query: '',
      visible: false,
      params: {},
      groupId: null,
      customerId: null
    }
  },
  methods: {
    // 初始化聊天类型
    async initChat() {
      try {
        const entry = await getContext()
        if (entry === 'group_chat_tools') {
          // 群聊
          this.groupId = await getCurExternalChat()
        } else if (entry === 'single_chat_tools') {
          // 单聊
          this.customerId = await getCurExternalContact()
        }
      } catch (e) {}
    },
    search(query) {
      if (query) {
        this.visible = true
        this.params = { query }
      } else {
        this.visible = false
        Toast('你需要输入点内容！')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/style/theme.less';

.search-left {
  margin-right: 6px;
  line-height: 34px;
  cursor: pointer;
}
.search-list {
  background-color: @white;
  padding: 12px 16px 0 16px;
  font-size: 14px;
}
</style>
